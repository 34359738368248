import React from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import TwoColumnLayout from "../components/layout/TwoColumnLayout";
import Sidebar from "../components/routes/Home/NewsAndEvents/Sidebar";
import { WmkSeo } from "wmk-seo";
import ModernPost from "../components/routes/Home/NewsAndEvents/NewsFeed/ModernPost";
import { NewsFields } from "./fragments/NodeNewsFields";
import { NewsPost } from "../classes/News";

export default ({ data }: { data: NewsQuery }) => {
  const news = new NewsPost(data.news);
  return (
    <>
      {news.slug !== "lake-ralph-hall-prepares-to-start-construction" && (
        <WmkSeo.Meta
          title={news.title}
          slug={news.slug}
          path="news/"
          siteTitle="UTRWD"
          baseUrl="https://utrwd.com/"
        />
      )}
      <Container style={{ padding: "8vh 1vh" }}>
        <TwoColumnLayout
          Main={() => (
            <ModernPost
              title={news.title}
              image={news.image}
              article={news.content}
              date={news.date}
              slug={news.slug}
              link={news.link}
              tags={news.tags}
              pdf={news.pdf}
            />
          )}
          Sidebar={Sidebar}
        />
      </Container>
    </>
  );
};

interface NewsQuery {
  news: NewsFields;
  newsTags: {
    edges: {
      node: { tags: string[] }[];
    };
  };
}

export const query = graphql`
  query newsQuery($slug: String!) {
    news: contentfulNews(slug: { eq: $slug }, visibility: { in: "UTRWD" }) {
      ...NodeNewsFields
    }

    newsTags: allContentfulNews(filter: { visibility: { in: "UTRWD" } }) {
      edges {
        node {
          tags
        }
      }
    }
  }
`;
