import React from "react";
import { StyledCard } from "./ModernFeed";
import { Row } from "react-bootstrap";
import { StyledDate } from "./ModernExcerpt";
import PageTitle from "../../../../common/typography/PageTitle";
import { WmkLink } from "wmk-link";
import { FaFilePdf } from "react-icons/fa";
import { Img, WmkImage } from "wmk-image";
import { RichText, RichTextReact } from "wmk-rich-text";
import { Tag } from "wmk-tag";
import { colors } from "../../../../ui/colors";
import { PdfFile } from "../../../../../classes/News";
import { options } from "../../../../Blocks/richTextOptions";
import ModernTags from "./ModernTags";

export const ModernPostTitle = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return (
    <PageTitle
      title={children}
      style={{ color: colors.cyan.getHex(), fontSize: "1.75rem" }}></PageTitle>
  );
};

const ModernPost = ({
  title,
  article,
  date,
  link,
  slug,
  image,
  tags,
  pdf
}: {
  title: string;
  article: RichText;
  date: Date;
  link?: string;
  slug: string;
  image: Img;
  tags: Tag[];
  pdf?: PdfFile;
}) => {
  return (
    <>
      <StyledCard>
        <Row className="flex-column" style={{ padding: "0 2vh" }}>
          <ModernPostTitle>{title}</ModernPostTitle>
          <ModernTags tags={tags} all={true} />
          <Row style={{ width: "100%" }}>
            <StyledDate xs="auto">{date.toLocaleDateString()}</StyledDate>
          </Row>
          <WmkImage image={image} style={{ marginTop: "1vh" }} />
        </Row>
      </StyledCard>
      <Row>
        <RichTextReact content={article} options={options} />
        {link && (
          <WmkLink to={link} target={"blank"}>
            Read full article.
          </WmkLink>
        )}
        {pdf ? (
          <WmkLink to={pdf.url} target={"self"}>
            <FaFilePdf /> {pdf.title}
          </WmkLink>
        ) : null}
      </Row>
    </>
  );
};

export default ModernPost;
